// * { box-sizing: border-box; }

.video-background {
  background: #000;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
}


.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
@media only screen and (min-width: 760px) {
  .video-foreground, .video-background iframe {
    position: absolute;
    top: -9%;
    left: -20%;
    width: 140%;
    height: 120%;
    pointer-events: none;
    /* border-radius: 60px; */
  }
}

.vid-info { position: absolute; top: 8%; right: -8%;
   background: rgba(0,0,0,0.3);z-index: 999;}

#vidtop-content {
	top: 0;
	color: #fff;
}